import { Component, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ViewCell } from 'ng2-smart-table';

@Component({
    styleUrls: ['./style.component.scss'],
    template: `
    <img [src]="url" alt="#" class="mini-lander"
        (click)="open(dialog)">

    <ng-template #dialog let-data let-ref="dialogRef">
      <nb-card>
        <nb-card-body>
            <div class="img_container">
                <div class="d-flex flex-column">
                <h5>Desktop</h5>
                <img [src]="data.web" alt="#" class="lander_web">
                </div >
                <div class="d-flex flex-column">
                <h5>Mobile</h5>
                <img [src]="data.mob" alt="#" class="lander_mob">
                </div>
                
            </div>
        </nb-card-body>
      </nb-card>
    </ng-template>
  `,
})
export class previewLanderModalComponent implements ViewCell {
    @Input() value: any;
    @Input() rowData: any;

    public url:string;
    public url_mobile:string;

    constructor(private dialogService: NbDialogService) { }
    ngOnInit() {
        this.url = this.rowData.url_preview;
        this.url_mobile = this.rowData.url_preview_mob;
    }

    open(dialog: TemplateRef<any>) {
        this.dialogService.open(dialog, { context: {web:this.url, mob:this.url_mobile} });
    }
}