import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription, timer} from 'rxjs';
import {shareReplay, takeUntil} from 'rxjs/operators';
import {ApiService} from "./api.service";

@Injectable({
    providedIn: 'root'
})
export class DataRefreshService {
    private destroy$ = new Subject<void>();
    private refreshInterval: number = 60000; // Default to 1 minute (60000ms)
    private counterSubRefresh: Subscription | null = null;
    private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private currentIntervalMinutes: number | null = null;


    constructor(private apiService: ApiService) {}

    startRefreshCycle(intervalMinutes: number = 1): void {
        if (this.currentIntervalMinutes === intervalMinutes) {
            return;
        }
        // set the refresh interval
        this.refreshInterval = intervalMinutes * 60000;

        // if an interval is already active, stop it
        this.stopRefreshCycle();
        this.currentIntervalMinutes = intervalMinutes;
        // start the interval
        this.counterSubRefresh = timer(0, this.refreshInterval)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.refreshData();
            });
    }

    stopRefreshCycle(): void {
        if (this.counterSubRefresh) {
            this.counterSubRefresh.unsubscribe();
            this.counterSubRefresh = null;
        }
        this.currentIntervalMinutes = null;
    }

    refreshData(): void {
        this.apiService.getDataOfPendingChats()
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: any) => {
                this.dataSubject.next(res.data);
            });
    }

    getDataObservable(): Observable<any> {
        return this.dataSubject.asObservable().pipe(shareReplay(1));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.stopRefreshCycle();
    }
}
