import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
    NbMediaBreakpointsService,
    NbMenuService,
    NbSidebarService,
    NbThemeService,
    NbDialogService
} from '@nebular/theme';
import {LayoutService} from '../../../@core/utils';
import {map, takeUntil, tap} from 'rxjs/operators';
import {interval, Observable, Subject, Subscription} from 'rxjs';
import {ApiService} from '../../../services/api.service';

import {ticketDialogComponent} from '../../dialogs'

import {NbToastrService, NbComponentStatus} from '@nebular/theme';
import {ChatterStatus, ChatterStatusService} from "../../../services/chatter-status.service";
import {MainUserIdService} from "../../../services/mainUserId.service";
import {TokenService} from "../../../services/token.service";
import {CurrentUserDataService} from "../../../services/profile-data.service";
import {ChattingService} from '../../../services/chatting.service';
import {DataRefreshService} from '../../../services/data-refresh.service';
import {NavigationStart, Router} from '@angular/router';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit, OnDestroy {
    affiliates: any = [];
    clientId: number;


    private destroy$: Subject<void> = new Subject<void>();
    public chatterStatuses: Subject<ChatterStatus[]>;

    public userPictureOnly: boolean = false;
    public user: any;
    public currentTheme: string = 'default';
    public userMenu = [
        {title: 'Profile', link: '/my-profile'},
        {title: 'Log out', link: '/auth/logout'}
    ];

    public pendingChatsCount$ = this.chattingService.pendingChatsCount$;
    public showCount = false;


    constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private apiService: ApiService,
        private layoutService: LayoutService,
        private breakpointService: NbMediaBreakpointsService,
        private dialogService: NbDialogService,
        private toastrService: NbToastrService,
        private chatterStatusService: ChatterStatusService,
        private mainUserIdService: MainUserIdService,
        private tokenService: TokenService,
        private currentUserDataService: CurrentUserDataService,
        private chattingService: ChattingService,
        private dataRefreshService: DataRefreshService,
        private router: Router
    ) {
        const role = this.tokenService.role.getValue();
        if ([7, 8, 11].includes(role)) this.showCount = true;
    }

    public ngOnInit(): void {
        this.currentTheme = this.themeService.currentTheme;
        this.chatterStatuses = this.chatterStatusService.statuses;
        this.getMyProfile();

        // initial route on page load
        this.handleInitialRoute(this.router.url);

        // route changes
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.handleRouteChange(event.url);
            }
        });


        this.mainUserIdService.allowed_clients$.subscribe(clients => {
            this.affiliates = clients;
        });

        this.mainUserIdService.client_id$.subscribe(id => {
            this.clientId = id;
        });

        this.dataRefreshService.getDataObservable().subscribe(data => {
            if (data) this.chattingService.pendingChatsCount$.next(data.pending_count);
        });


        const {xl} = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange().pipe(
            map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
            takeUntil(this.destroy$),
        )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    }

    private handleInitialRoute(url: string): void {
        this.handleRouteChange(url);
    }

    private handleRouteChange(url: string): void {
        if (url.includes('chatting')) {
            // If on chatting page, set the interval to 1 minute
            this.dataRefreshService.startRefreshCycle(1);
        } else if (!url.includes('auth') && !url.includes('chatting')) {
            // If not on the chatting page or auth, set to 2-min
            if (this.showCount) {
                this.dataRefreshService.startRefreshCycle(2);
            } else {
                this.dataRefreshService.stopRefreshCycle();
            }
        } else if(url.includes('auth')) {
            this.dataRefreshService.stopRefreshCycle();
        }
    }

    disableOption(status: any): boolean {
        return status === 0 ||
            (status === 1 && this.user?.chatter_status_id !== 7) ||
            (status === 4 && this.user?.chatter_status_id !== 7) ||
            (status === 8 && this.user?.chatter_status_id !== 7);
    }

    public onChatterStatusChange(status_id: number): void {
        const status = this.chatterStatusService.getStatus(status_id);
        if (status_id && status_id !== this.user?.chatter_status_id) {
            this.user.chatter_status_id = status_id;
            this.chatterStatusService.setChatterStatus(status_id)

            let data = {
                id: status.id,
                name: status.name,
                status: status.status
            }
            this.apiService.updateChatterStatusLog(data).subscribe((res: any) => {
                this.showToast('ChatterStatus', 'Status updated ...');
            });
        }
    }

    public toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();

        return false;
    }

    public navigateHome(): boolean {
        this.menuService.navigateHome();
        return false;
    }


    public ticketDialog(ticket: any = null): void {
        this.dialogService.open(ticketDialogComponent, {
            context: {
                import_ticket: ticket,
            }
        }).onClose.subscribe();
    }


    changeMainUser(id) {

        if (this.affiliates.length == 1) return;

        this.apiService.updateUsersMainUserData(id).subscribe(
            (res: any) => {
                this.mainUserIdService.setColorApp(id);
            },
            (error: any) => {
                this.showToast('Error', error.error.message, 'danger');
            }
        )
        this.mainUserIdService.setClientId(id, true);

    }


    getMyProfile(): void {
        this.currentUserDataService.fetchUserProfile();
        this.currentUserDataService.getCurrentUserData().pipe(
            tap(userData => {
                if (this.user && this.user.chatter_status_id !== userData.chatter_status_id) {
                    this.chatterStatusService.setChatterStatus(this.user.chatter_status_id);
                }
                this.user = userData;
            })
        ).subscribe();

    }


    public ngOnDestroy(): void {
        this.dataRefreshService.stopRefreshCycle();
    }


    showToast(title, message, status: NbComponentStatus = 'success') {
        this.toastrService.show(title, message, {status});
    }
}
